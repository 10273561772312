import React, { Suspense, Fragment, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
 import HeaderLayout from './Layout/HeaderLayout';
import Authenticated from './Guard/Authenticated';
import Guest from './Guard/Guest';
import BaseLayout from './Layout/BaseLayout';
import LoadScreen from './LoadScreen';




export function RenderRout() {
  
    return (
      <>
       <Router>
          <Suspense fallback={<LoadScreen/>}
          >
              <Routes>
              {routes?.map((route, i) => {
                const Guard = route?.guard || Fragment;
                const Layout = route?.layout || Fragment;
                const Component = route?.element;
                return (
                  <Route
                    key={i}
                    path={route.path}
                    exact={route.exact}
                    element={
                      <Guard>
                        <Layout >
                          <Component />
                          </Layout>
                      </Guard>
                    }
                  />
                );
              })}
            </Routes>
          </Suspense>
        </Router>
         </>
    )
  
  }

  const routes = [
    //Auth Pages
    {
      layout:BaseLayout,
      exact: true,
      path: '/send-review',
      element: lazy(() => import('./Component/Pages/SendReview/Index'))
    },
    {
      layout:BaseLayout,
      exact: true,
      path: '/review-show',
      element: lazy(() => import('./Component/Pages/SendReview/ReviewIntro'))
    },
    {
      guard: Guest,
      layout:BaseLayout,
      exact: true,
      path: '/login/:type',
      element: lazy(() => import('./Component/Auth/Login'))
    },

    {
      guard: Guest,
      layout:BaseLayout,
      exact: true,
      path: '/account-type',
      element: lazy(() => import('./Component/Pages/Account/NoAccount'))
    },


   
//Restaurants
{
 
  layout:HeaderLayout,
  exact: true,
  path: '/',
  element: lazy(() => import('./Component/Pages/Restaurants/Index'))
},

{
  layout:HeaderLayout,
  exact: true,
  path: '/restaurants/details/:id',
  element: lazy(() => import('./Component/Pages/Restaurants/Restaurants_view'))
},

{
  layout:HeaderLayout,
  exact: true,
  path: '/in_restaurants/details/:id',
  element: lazy(() => import('./Component/Pages/Restaurants/In_network_view'))
},

// Account
{
  guard: Authenticated,
  layout:HeaderLayout,
  exact: true,
  path: '/my-profile',
  element: lazy(() => import('./Component/Pages/Account/Index'))
},


    ///404 Page

{
  layout:BaseLayout,
  exact: true,
  path: '*',
  element: lazy(() => import('./Component/PageNotFound'))
  
},
]