import React from "react";
import { Usertype } from "../config";
import { AccountSvg, HomeSvg, ReviewSvg } from "../SvgFile/Index";
import FooterLink from "./FooterLink";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const [links, setLinks] = React.useState([]);
  const user = JSON.parse(localStorage.getItem("userData"));
  const token = localStorage.getItem("accessToken");
  const { t } = useTranslation();
  const user_links = [
    {
      link: "/",
      title: t("Home"),
      icone: <HomeSvg />,
    },
    {
      link: "/send-review",
      title: t("Review"),
      icone: <ReviewSvg />,
    },

    {
      link: token ? "/my-profile" : "/account-type",
      title: t("Account"),
      icone: <AccountSvg />,
    },
  ];

  const driver_links = [];
  const restaurant_links = [];

  React.useEffect(() => {
    if (user?.userType === Usertype.restaurant) {
      setLinks(restaurant_links);
    } else if (user?.userType === Usertype.driver) {
      setLinks(driver_links);
    } else {
      setLinks(user_links);
    }
  }, []);

  return (
    <>
      <div className="footerbtm">
        <div className="mobilemenubottom d-flex align-items-center justify-content-between">
          {links.map((item, i) => {
            return (
              <FooterLink
                key={i}
                href={item.link}
                name={item.title}
                icon={item.icone}
              />
            );
          })}
        </div>
      </div>
    </>
  );
}
